import * as CompaniesService from "../../services/companiesServices/CompaniesService";
import * as FileService from "../../services/FilesService";
import * as ClientConfig from "../../config/ClientConfig";
import {editEmployeeById} from "../../services/companiesServices/CompaniesEmployeesService";
import {autoClockOutAllEmployees} from "../../services/clockingRecordServices/ClockingRecordService";


export const setPictureHandler = async (pictureFile) => {
    const returnedImage = await FileService.insertFile(pictureFile);
    if (!returnedImage.ok) {
        return {
            data: {
                error: "Error setPicture",
            }
        }
    }
    return {
        data : {
            name: returnedImage.data.name,
            url: returnedImage.data.url,
        }

    }
}

export const getCompanyByIdHandler = async (id) => {
    const response = await CompaniesService.getCompanyById(id);
    if (!response.ok) {
        return {
            data: {
                error: "Error getServiceProviderById",
            }
        }
    }

    const companyDetails = {
        id: response.data.id,
        name: response.data.name,
        // picture: ClientConfig.URL_RESOURCE_SERVER + "/api/files/" + response.data.picture,
        picture: response.data.picture,
        email: response.data.email,
        phone: response.data.phone,
        timeZone: response.data.timeZone,
        locale: response.data.locale,
        locations: response.data.locations.map(e => e.id),
        nfcs: response.data.nfcs.map(e => e.id),
        schedulerEnabled:response.data.schedulerEnabled,
        pauseThresholds: response.data.pauseThresholds,
        autoClockOut:response.data.autoClockOut,
        leaveEnabled: response.data.leaveEnabled,
        showWorkHistoryInMobileApp: response.data.showWorkHistoryInMobileApp
    }


    return {
        data: {
            error: null,
            company: companyDetails
        }
    };

}



export const editCompanyHandler = async (id, data) => {
    let error = null;
    const body = {
        "companyId": data.companyId,
        "name": data.name,
        "email": data.email,
        "phone":data.phone,
        "imageName": data.picture,
        "timeZone":data.timeZone,
        "locale":data.locale,
        "schedulerEnabled": data.schedulerEnabled,
        "pauseThresholds":data.pauseThresholds,
        "autoClockOut":data.autoClockOut,
        "leaveEnabled":data.leaveEnabled,
        "showWorkHistoryInMobileApp":data.showWorkHistoryInMobileApp

    }
    const response = await CompaniesService.editCompanyById(id, body);
    if (response.ok) {

    } else {
        error ="Error editServiceProviderHandler";
    }
    return {
        data: {
            error: error
        }
    };
}



export const autoClockOutAllEmployeesHandler = async () => {
    let error = null;

    const response = await autoClockOutAllEmployees();
    if (response.ok) {
    } else {
        error ="Error autoClockOutAllEmployees";
    }
    return {
        data: {
            error: error,

        }
    };
}



