
import {
    getAllEmployeesFromCompany
} from "../../../services/companiesServices/CompaniesEmployeesService";
import * as FacilitiesEmployeesService from "../../../services/companiesServices/CompaniesEmployeesService";
import * as FacilitiesEmployeeService from "../../../services/companiesServices/CompaniesEmployeesService";
import {getCompanyById} from "../../../services/companiesServices/CompaniesService";
import {getAllRolesFromCompany} from "../../../services/companiesServices/CompaniesRolesService";
import * as StorageService from "../../../services/StorageServices";
import {LOCALSTORAGE_ITEMS} from "../../../util/Constants";
import {resetTime} from "../../../util/common";
import {getClockInOut} from "../../../services/clockingRecordServices/ClockingRecordService";



export const getAllEmployeesByCompanyIdHandler = async (companyId) => {
    let error = null;
    const companyDetailsResponse = await getCompanyById(companyId);
    const allEmployeesResponse = await getAllEmployeesFromCompany(companyId);
    const rolesResponse = await getAllRolesFromCompany(companyId);

    const today = resetTime(new Date());

    const endOfDay = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    const clockedEmployeesResponse = await getClockInOut(today, endOfDay, companyId);

    let companyAndEmployeesResponse = null;

    if (companyDetailsResponse.ok && allEmployeesResponse.ok && rolesResponse.ok) {
        StorageService.setItem(LOCALSTORAGE_ITEMS.COMPANY_INFO, JSON.stringify(companyDetailsResponse.data));
        companyAndEmployeesResponse = {
            id: companyDetailsResponse.data.id,
            name: companyDetailsResponse.data.name,
            schedulerEnabled: companyDetailsResponse.data.schedulerEnabled,
            leaveEnabled: companyDetailsResponse.data.leaveEnabled,
            allRoles: rolesResponse.data,
            employees: allEmployeesResponse.data.map(employee => {
                return {
                    id: employee.id,
                    roles: employee.roles,
                    phone: employee.phone,
                    name:  employee.firstName + " " + employee.lastName,
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    clockingLocations: employee.clockingLocations,
                    clockedLocations: clockedEmployeesResponse.data.content.filter(clockedEmployee => clockedEmployee.employee.id === employee.id).map(clockinInfo=> clockinInfo.location.name),
                    email: employee.email,
                    managedRoles: employee.managedRoles,
                    managedLocations: employee.managedLocations,
                    type: employee.type,
                    created:  employee.created
                }
            })
        }

        companyAndEmployeesResponse.employees.sort(function(a, b){return (a.name).localeCompare(b.name)});

    } else {
        error ="Error getAllEmployeesByCompanyIdHandler";
    }
    return {
        data: {
            error: error,
            allCompanyAndEmployees: companyAndEmployeesResponse
        }
    };
}



export const deleteFacilityEmployeeHandler = async (feid) => {
    const response = await FacilitiesEmployeesService.deleteEmployeeById(feid);
    let error = null;
    if (response.ok) {

    } else {
        error ="Error deleteFacilityEmployeeHandler";
    }

    return {
        data: {
            error: error
        }
    };
}



export const unlinkManagerFromFacilityHandler = async (feid, fid) => {
    let error = null;

    const response = await FacilitiesEmployeeService.unlinkCompanyEmployeeFromCompany(feid, fid);
    if (response.ok) {
        return "OK"
    } else {
        error ="Error unlinkManagerFromFacilityHandler";
    }
    return {
        data: {
            error: error
        }
    };
}



