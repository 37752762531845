import * as StorageService from "../services/StorageServices";
import {APP_DOMAINS, LOCALSTORAGE_ITEMS} from "../util/Constants";
import React from "react";
import {APP_VERSION} from "../config/ClientConfig";
import {PATH} from "../routes/routes";
import * as ClientConfig from "../config/ClientConfig";

const M_MILLIS = 60*1000;
const H_MILLIS = 60*M_MILLIS;
const D_MILLIS = 24*H_MILLIS;

export const getDateObjectTmz = (d, tmz, locale) => {
    const intlDateObj = new Intl.DateTimeFormat(
        locale || StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE),
        {
        timeZone: tmz || StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE),
        year: "numeric",
        month: "numeric",
        day: "numeric",
        weekday: "short",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hourCycle: "h23",
        fractionalSecondDigits: "3"
        });

    return Object.fromEntries(new Map(intlDateObj.formatToParts(d).filter(e => e.type !== "literal").map(e => {
        // fix bug for chrome 80
       //return [e.type, (e.type==="hour" && parseInt(e.value) == "24")?"0":e.value];
       return [e.type, e.value];
        // return [e.type, (e.type === "year" && e.value.length === 2)?("21"+e.value):e.value];
    })));
};

// function isValidDate(d) {
//     return d instanceof Date && !isNaN(d);
// }
export const newDateTmzWithFields = (d, tmz, fields) => {
    const intlDateObj = new Intl.DateTimeFormat(
        "en-US",
        {
        timeZone: tmz,
        day:"numeric",
        month:"numeric",
        year: "numeric",
        hour:"numeric",
        minute:"numeric",
        second:"numeric",
        hourCycle: "h23",
        timeZoneName: "longOffset"
        });
    let dtmz = new Date(intlDateObj.formatToParts(d).map(e => (typeof(fields) !== "undefined" && typeof(fields[e.type]) !== "undefined")?fields[e.type]:e.value).join(""));

    // if (!isValidDate(dtmz)) {
    //     dtmz = d;
    //     console.error("TIMEZONE NOT SUPPORTED YET: ", tmz);
    // }

    if((typeof(fields) !== "undefined" && typeof(fields["millis"]) !== "undefined")){
        return new Date(dtmz.getTime() + fields["millis"]);
    }

    else {
        return dtmz;
    }
};

// console.log(newDateTmzWithFields(new Date(), "America/Los_Angeles",{year:"2022",month:"1",day:"1",hour:"0",minute:"0",second:"0"}))
// console.log("newDateTmzWithFields 1", newDateTmzWithFields(new Date("2022-01-01T10:10:00.000Z"), "UTC", {
//     hour:0,
//     minute:0,
//     second:0,
//     millis: 524
// }))

// console.log("newDateTmzWithFields 2", newDateTmzWithFields(new Date("2022-01-01T10:10:00.000Z"), "Europe/Bucharest", {
//     hour:0,
//     minute:0,
//     second:0,
//     millis: 524
// }))

const isLeapYear = (year) => {
    if(year < 100) year  = 2000 + year;
    if ((0 === year % 4) && (0 !== year % 100) || (0 === year % 400)) {
        return true;
    } else {
        return false;
    }
}

const addDateTmzWithFields = (d, tmz, fields) => {
    const intlDateObj = new Intl.DateTimeFormat(
        "en-US",
        {
        timeZone: tmz,
        day:"numeric",
        month:"numeric",
        year: "numeric",
        hour:"numeric",
        minute:"numeric",
        second:"numeric",
        hourCycle: "h23",
        timeZoneName: "longOffset"
        });


    const changedFields = {};
    let addTime = 0;
    if(typeof(fields)!=="undefined"){
        if(typeof(fields["hour"])!=="undefined"){
            addTime += fields["hour"]*H_MILLIS;
        }
        if(typeof(fields["minute"])!=="undefined"){
            addTime += fields["minute"]*M_MILLIS;
        }
        if(typeof(fields["second"])!=="undefined"){
            addTime += fields["second"]*1000;
        }

        const dateObjTmz = Object.fromEntries(new Map(
            intlDateObj.formatToParts(new Date(d.getTime() + addTime))
            .filter(e => e.type !== "literal")
            .map(e => {
            return [e.type, e.value];
            })));


        const currDayTmz = parseInt(dateObjTmz["day"]);
        const currMonthTmz = parseInt(dateObjTmz["month"]);

        const currYearTmz = parseInt(dateObjTmz["year"]);
        let addDay = currDayTmz;
        let addMonth = currMonthTmz;
        let addYear = currYearTmz;
        if(typeof(fields["day"])!=="undefined"){
            addDay += fields["day"];
        }
        if(typeof(fields["month"])!=="undefined"){
            addMonth += fields["month"];
        }
        if(typeof(fields["year"])!=="undefined"){
            addYear += fields["year"];
        }

        const dd = new Date(addYear, addMonth - 1, addDay);

        changedFields["day"] = dd.getDate();
        changedFields["month"] = dd.getMonth()+1;
        changedFields["year"] = dd.getFullYear();
        changedFields["hour"] = dateObjTmz["hour"];
        changedFields["minute"] = dateObjTmz["minute"];
        changedFields["second"] = dateObjTmz["second"];
    }

    const partstmz = intlDateObj.formatToParts(d).map(e => {
        const v = changedFields[e.type];
        if(v){
            return v;
        }else return e.value;
    });
    // console.log(partstmz);
    const dtmz = new Date(partstmz.join(""));
    // if(typeof(fields["millis"])!=="undefined"){
    //     return new Date(dtmz.getTime() + fields["millis"]);
    // }
    // else
    return dtmz;

};

export const addDateWithFields = (d, fields) => {
    return addDateTmzWithFields(d, StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE),fields);
};
// console.log("addDateTmzWithFields 1", addDateTmzWithFields(new Date("2022-01-01T22:10:00.000Z"), "UTC", {"hour":30}))
// export const addDateWithFields = (d, fields) => {
//     let addHour = d.getHours();
//     let addMinute = d.getMinutes();
//     let addSecond = d.getSeconds();
//     let addMillis = d.getMilliseconds();
//     let addDay = d.getDate();
//     let addMonth = d.getMonth();
//     let addYear = d.getFullYear();

//     if(typeof(fields)!=="undefined"){
//         if(typeof(fields["hour"])!=="undefined"){
//             addHour += fields["hour"];
//         }
//         if(typeof(fields["minute"])!=="undefined"){
//             addMinute += fields["minute"];
//         }
//         if(typeof(fields["second"])!=="undefined"){
//             addSecond += fields["second"];
//         }
//         if(typeof(fields["millis"])!=="undefined"){
//             addMillis += fields["millis"];
//         }
//         if(typeof(fields["day"])!=="undefined"){
//             addDay += fields["day"];
//         }
//         if(typeof(fields["month"])!=="undefined"){
//             addMonth += fields["month"];
//         }
//         if(typeof(fields["year"])!=="undefined"){
//             addYear += fields["year"];
//         }
//         return new Date(addYear, addMonth, addDay, addHour, addMinute, addSecond, addMillis);
//     }
//     return d;
// };
// console.log(new Date("2022-01-31T10:10:00.000Z"))
// console.log("yyy:",new Date(2022,0,31))
//
// console.log("yyy:",new Date(2022,0,32))

 // console.log("addDateWithFields:", addDateWithFields(new Date("2022-10-31T10:10:00.000Z"), {"day":1}))
// console.log("addDateWithFields 2", addDateWithFields(new Date("2022-01-01T22:10:00.000Z"), {"day":50}))




export const getTotalHoursAndMinsFromIntervalMillis = (intervals) => {
    if (intervals?.length > 0) {
       const firstInt = intervals[0];
       const lastInt = intervals[intervals.length - 1];
       const from = firstInt.from;
       const to = lastInt.to;
       let totalTime = 0;
       intervals.forEach(i => {

           if (i.workedTime) {
               totalTime += i.workedTime;
           }
       });

       const totalHoursAndMins = {
           from: getHourAndMinFromMillis(from),
           to: getHourAndMinFromMillis(to),
           totalTime: getTotalHoursAndMinsFromMillisWithLabels(totalTime),

       }
       return totalHoursAndMins;
    }else{
        return null;
    }
}

export const getTotalHoursFromIntervalMillis = (intervals) => {
    if (intervals?.length > 0) {
        const firstInt = intervals[0];
        const lastInt = intervals[intervals.length - 1];
        const from = firstInt.from;
        const to = lastInt.to;
        let totalTime = 0;
        intervals.forEach(i => {

            if (i.workedTime) {
                totalTime += i.workedTime;
            }
        });

        const totalHoursAndMins = {
            from: getHourAndMinObjectFomMillis(from),
            to:getHourAndMinObjectFomMillis(to),
        }
        return totalHoursAndMins;
    }else{
        return null;
    }
}


const roundToMinutes = (millis) => {
    return Math.round(millis / 1000 / 60);
}

export const roundDateToMinutes = (d) => {
    return new Date(Math.round(d.getTime() / 60000) * 60000 )
};

export const roundToHoursAndMinutes = (millis) => {
    let allMinutes = roundToMinutes(millis);
    let hours = Math.floor(allMinutes / 60);
    return {
        h: hours,
        m: allMinutes - hours * 60
    }
}

export const getTotalHoursAndMinsFromMillisWithLabels = (totalMillis, emptyStr) => {
    const {h , m} = roundToHoursAndMinutes(totalMillis);

    if (h === 0 && m === 0) {
        return typeof(emptyStr) !== "undefined"?emptyStr: "-";
    }
    if (h === 0) {
        return m + "m";
    } else if (m === 0) {
        return h + "h";
    } else {
        return (h + "h " + m + "m");
    }
}

export const getHourAndMinFromMillis = (totalMillis) => {
    if (totalMillis === undefined) {
        return "none"
    }
    const {h , m} = roundToHoursAndMinutes(totalMillis);

    let hs = "";
    let ms = "";
    if(h < 10){
        hs = "0";
    }
    hs = hs + h;
    if(m < 10){
        ms = "0";
    }
    ms = ms + m;
    return (hs + ":" + ms);
}

export const getHourAndMinObjectFomMillis = (totalMillis) => {
    const {h, m} = roundToHoursAndMinutes(totalMillis);

    let hs = "";
    if(h < 10){
        hs = "0";
    }
    hs = hs + h;


    let ms = "";


    if(m < 10){
        ms = "0";
    }
    ms = ms + m;


    return {
        h:hs,
        m: ms
    };
}

const getFirstDayOfMonthTmz = (date, tmz) => {
    return newDateTmzWithFields(date, tmz, {day:1, hour: 0, minute: 0, second: 0});
}

export const getFirstDayOfMonth = (date) => {
    return getFirstDayOfMonthTmz(date, StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE));
}

export const getFirstDayOfNextMonthDate = (date) => {
    return getFirstDayOfMonth(new Date(getLastDayOfMonth(date).getTime() + 48 * 60 * 60 * 1000))
};


// console.log(new Date("2023-02-28T13:00:00.000Z"))
// console.log("getFirstDayOfMonthTmz:",getFirstDayOfMonthTmz(new Date("2022-01-31T23:10:00.000Z"), "UTC"))
// console.log("getFirstDayOfMonthTmz:",getFirstDayOfMonthTmz(new Date("2022-01-31T23:10:00.000Z"), "Europe/Bucharest"))
// console.log("getFirstDayOfMonthTmz:",getFirstDayOfMonthTmz(new Date("2022-01-31T23:10:00.000Z"), "Europe/Bucharest"))
// console.log("getFirstDayOfMonthTmz:",getFirstDayOfMonthTmz(new Date("2023-02-28T13:00:00.000Z"), "Australia/Sydney"))

const getLastDayOfMonthTmz = (date, tmz) => {
    const dtmzFirst = newDateTmzWithFields(date, tmz, {day:1, hour: 0, minute: 0, second: 0})
    return addDateTmzWithFields(dtmzFirst, tmz, {month:1});
}

export const getLastDayOfMonth = (date) => {
    return getLastDayOfMonthTmz(date, StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE));
}

// console.log(new Date("2022-01-31T23:10:00.000Z"))
// console.log("getLastDayOfMonthTmz:",getLastDayOfMonthTmz(new Date("2022-01-31T23:10:00.000Z"), "UTC"))
// console.log("getLastDayOfMonthTmz:",getLastDayOfMonthTmz(new Date("2022-01-31T23:10:00.000Z"), "Europe/Bucharest"))

// March month must be 2023-03-30T13:00:00.000Z
// console.log("getLastDayOfMonthTmz:",getLastDayOfMonthTmz(new Date("2023-02-28T13:00:00.000Z"), "Australia/Sydney"))

const getTotalDaysInMonthTmz = (date, tmz) => {
    const dtmzObj = getDateObjectTmz(date, tmz, "en-US");
    return new Date(parseInt(dtmzObj["year"]), parseInt(dtmzObj["month"]), 0).getDate();
}

export const getTotalDaysInMonth = (date) => {
    return getTotalDaysInMonthTmz(date, StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE));
}

// console.log("getTotalDaysInMonthTmz: ", getTotalDaysInMonthTmz(new Date("2022-01-31T23:10:00.000Z"), "UTC"))
// console.log("getTotalDaysInMonthTmz: ", getTotalDaysInMonthTmz(new Date("2022-01-31T23:10:00.000Z"), "Europe/Bucharest"))

const getDayNameTmz = (date, tmz, i, locale) => {
    const newDate = newDateTmzWithFields(date, tmz, {day:i,hour:0,minute:0,second:0,millis:0})
    return newDate.toLocaleDateString(locale, { timeZone: tmz, weekday: 'short' });
}

export const getDayName = (date, i, locale) => {
    return getDayNameTmz(date, StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE), i, locale);
}


// console.log(new Date("2022-12-31T23:10:00.000Z"))
//  console.log("getDayNameTmz:",getDayNameTmz(new Date("2022-12-31T23:10:00.000Z"), "UTC", 1, "ro"))
// console.log("getDayNameTmz:",getDayNameTmz(new Date("2022-12-31T23:10:00.000Z"), "Europe/Bucharest",1, "ro"))
export const formatDate = (date) => {

    return new Date(date).toLocaleDateString(StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE), {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
};
const getWeekendsTmz = (date, tmz) => {

    let getTot = getTotalDaysInMonthTmz(date, tmz); //Get total days in a month
    let dtmz = getDateObjectTmz(date, tmz);
    const month = parseInt(dtmz["month"])-1; // -1 because Date uses from 0
    const year = parseInt(dtmz["year"]);
    let sat = [];   //Declaring array for inserting Saturdays
    let sun = [];   //Declaring array for inserting Sundays

    let weekends = [];


    for(let i=1;i<=getTot;i++){    //looping through days in month
        let newDate = new Date(year,month,i)
        if(newDate.getDay() === 0){   //if Sunday
            sun.push(i);
            weekends.push(i);
        }
        if(newDate.getDay() === 6){   //if Saturday
            sat.push(i);
            weekends.push(i);
        }

    }
    return weekends;
}


export const getUserAgent = () => {
    const userAgent = window.navigator.userAgent;

    if (userAgent.includes("Firefox")) {
        return "Mozilla Firefox";
    } else if (userAgent.includes("SamsungBrowser")) {
        return "Samsung Internet";
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
        return "Opera";
    } else if (userAgent.includes("Edg")) {
        return "Microsoft Edge";
    } else if (userAgent.includes("Chrome") && !userAgent.includes("Chromium")) {
        return "Google Chrome";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        return "Apple Safari";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) {
        return "Internet Explorer";
    } else {
        return "Unknown browser";
    }
}


export const getClassByType = (type) => {
    const allLeavesTypes = StorageService.getItem(LOCALSTORAGE_ITEMS.LEAVE_TYPES) ? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.LEAVE_TYPES)) : null;
    const classMapping = {};
    allLeavesTypes.forEach(({ id }) => {
        classMapping[id] = id.replace(/_/g, "-").toLowerCase();
    });
    return classMapping[type] || "";
};

export const getLeaveTypeName = (typeId) => {
    const locale = StorageService.getItem(LOCALSTORAGE_ITEMS.LOCALE);
    const allLeavesTypes = StorageService.getItem(LOCALSTORAGE_ITEMS.LEAVE_TYPES) ? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.LEAVE_TYPES)) : null;
    const leaveType = allLeavesTypes.find(type => type.id === typeId);
    if (leaveType) {
        return leaveType[locale] || leaveType.en;
    }
    return typeId;
};

export const getLeaveRequirements = (typeId) => {
    const allLeavesTypes = StorageService.getItem(LOCALSTORAGE_ITEMS.LEAVE_TYPES)
        ? JSON.parse(StorageService.getItem(LOCALSTORAGE_ITEMS.LEAVE_TYPES))
        : null;

    if (!allLeavesTypes) return null;

    const leaveType = allLeavesTypes.find(type => type.id === typeId);

    if (leaveType) {
        if (leaveType.req_ro || leaveType.req_en) {
            return {
                req_ro: leaveType.req_ro ? leaveType.req_ro : null,
                req_en: leaveType.req_en ? leaveType.req_en : null,
            };
        }
    }

    return null;
};



export const isLeaveInMonthRange = (leave, selectedDate) => {
    const leaveStartDate = new Date(leave.from);
    const leaveEndDate = new Date(leave.to);


    const fromMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
    const toMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 2, 0);
    //
    // console.log('Selected Month Start:', fromMonth);
    // console.log('Selected Month End:', toMonth);
    // console.log('Leave Start:', leaveStartDate);
    // console.log('Leave End:', leaveEndDate);
    // console.log('Is Leave in Month Range:', leaveStartDate <= toMonth && leaveEndDate >= fromMonth);
    return (leaveStartDate <= toMonth && leaveEndDate >= fromMonth);
};
export const getWeekends = (date) => {
    return getWeekendsTmz(date)
}

// console.log(new Date("2023-03-31T23:10:00.000Z"))
// console.log("getWeekendsTmz:",getWeekendsTmz(new Date("2023-03-31T23:10:00.000Z"), "UTC"))
// console.log("getWeekendsTmz:",getWeekendsTmz(new Date("2023-03-31T23:10:00.000Z"), "Europe/Bucharest"))
// console.log("getWeekendsTmz:",getWeekendsTmz(new Date("2023-03-31T23:10:00.000Z"), "America/Los_Angeles"))


export const resetTime = (date) => {
    return resetTimeTmz(date, StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE));
}

const resetTimeTmz = (date, tmz) => {
    return newDateTmzWithFields(date, tmz, {hour:0, minute: 0, second: 0, millis: 0});
}

const resetTimeToEndOfDayTmz = (date, tmz) => {
    return newDateTmzWithFields(date, tmz, {hour:23, minute: 59, second: 59, millis: 999});
}

export const resetTimeToEndOfDay = (date) => {
    return resetTimeToEndOfDayTmz(date, StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE));
}

// console.log(new Date("2023-01-31T23:10:00.000Z"))
// console.log("resetTimeTmz: ",resetTimeTmz(new Date("2023-01-31T23:10:00.000Z"), "UTC"));
// console.log("resetTimeTmz: ",resetTimeTmz(new Date("2023-01-31T23:10:00.000Z"), "Europe/Bucharest"));
// const dddd = "2022-03-26T22:00:00.000Z"
// console.log(new Date(dddd))
// const d1 = resetTimeTmz(new Date(dddd),"Europe/Bucharest")
// const d2 = addDateWithFields(new Date(dddd), {"day":1})
// console.log("start 1", d1)
// console.log("end 1", d2)
// console.log(D_MILLIS)
// console.log((d2.getTime()-d1.getTime()))
// console.log((d2.getTime()-d1.getTime())/H_MILLIS)
// console.log("end 1", addDateWithFields(new Date("2022-01-01T22:10:00.000Z"), "Europe/Bucharest", {"day":1}))
// console.log("resetTimeTmz: ",resetTimeTmz(new Date("2025-01-10T16:10:00.000Z"), "America/Antigua"));
// console.log("resetTimeTmzWhichWorks: ",resetTimeTmz(new Date("2025-01-10T16:10:00.000Z"), "Europe/Bucharest"));


export const getTimezone = () => {
    return StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE) || new Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const isDateWithinRange = (rangeStart, rangeEnd, dateStart, dateEnd) => {
    return (
        (dateStart >= rangeStart && dateStart <= rangeEnd) ||
        (dateEnd >= rangeStart && dateEnd <= rangeEnd) ||
        (dateStart <= rangeStart && dateEnd >= rangeEnd)
    );
};

export const clearStorageItemsAndLogout = () => {
    StorageService.removeItem(LOCALSTORAGE_ITEMS.FIRST_NAME);
    StorageService.removeItem(LOCALSTORAGE_ITEMS.LAST_NAME);
    StorageService.removeItem(LOCALSTORAGE_ITEMS.TYPE);
    StorageService.removeItem(LOCALSTORAGE_ITEMS.ACCESS_TOKEN);
    StorageService.removeItem(LOCALSTORAGE_ITEMS.REFRESH_TOKEN);
    StorageService.removeItem(LOCALSTORAGE_ITEMS.USERNAME);
    StorageService.removeItem(LOCALSTORAGE_ITEMS.USER_ID);
    StorageService.removeItem(LOCALSTORAGE_ITEMS.COMPANY_INFO);
    StorageService.removeItem(LOCALSTORAGE_ITEMS.LEAVE_TYPES);
    StorageService.removeItem(LOCALSTORAGE_ITEMS.LOCALE);
    window.location.href = "#" + PATH.LOGIN;
}


export const handleAppVersionUpdate = () => {
    if (StorageService.getItem(LOCALSTORAGE_ITEMS.APP_VERSION) !== APP_VERSION) {
        StorageService.setItem(LOCALSTORAGE_ITEMS.APP_VERSION, APP_VERSION);
        clearStorageItemsAndLogout();
    }
}


export const selectStyles = {
    control: provided => ({
        ...provided,
        boxShadow: 'none',

    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#A9ACBE',
        }
    }
}

export const getMillis = (hoursMinutesString) => {
    const hm = hoursMinutesString.split(":");

    return parseInt(hm[0]) * 60*60*1000 + parseInt(hm[1]) * 60*1000;
}

const getStartDayTimeMillisTmz = (date, tmz) => {
    return newDateTmzWithFields(date, tmz, {hour: 0, minute: 0, second: 0});
}

const getStartDayTimeMillis = (date) => {
   return getStartDayTimeMillisTmz(date, StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE));
}

// console.log("getStartDayTimeMillisTmz:", getStartDayTimeMillisTmz(new Date(), "Europe/Bucharest"));
// console.log("getStartDayTimeMillisTmz:", getStartDayTimeMillisTmz(new Date(), "UTC"));

const getEndDayTimeMillisTmz = (date, tmz) => {
    return addDateWithFields(newDateTmzWithFields(date, tmz, {hour: 0, minute: 0, second: 0}),{day:1})
}

const getEndDayTimeMillis = (date) => {
   return getEndDayTimeMillisTmz(date, StorageService.getItem(LOCALSTORAGE_ITEMS.TIMEZONE));
}

// console.log("getEndDayTimeMillisTmz:", getEndDayTimeMillisTmz(new Date(), "UTC"));
// console.log("getEndDayTimeMillisTmz:", getEndDayTimeMillisTmz(new Date(), "Europe/Bucharest"));


const getHourMinuteOffsetTmz = (d, tmz) => {
    const dTmz = getDateObjectTmz(d, tmz);
    const currentDateTnz = getDateObjectTmz(d);

    return {
        hour: currentDateTnz.hour - dTmz.hour,
        minute: currentDateTnz.minute - dTmz.minute
    };
}



export const getLocales = () => {
    let locales =[
        ['Abkhazian', 'ab'],
        ['Afar', 'aa'],
        ['Afrikaans', 'af'],
        ['Akan', 'ak'],
        ['Albanian', 'sq'],
        ['Amharic', 'am'],
        ['Arabic', 'ar'],
        ['Aragonese', 'an'],
        ['Argentina', 'es-ar'],
        ['Armenian', 'hy'],
        ['Assamese', 'as'],
        ['Avaric', 'av'],
        ['Avestan', 'ae'],
        ['Aymara', 'ay'],
        ['Azerbaijani', 'az'],
        ['Bambara', 'bm'],
        ['Bashkir', 'ba'],
        ['Basque', 'eu'],
        ['Belarusian', 'be'],
        ['Bengali (Bangla)', 'bn'],
        ['Bihari', 'bh'],
        ['Bislama', 'bi'],
        ['Bosnian', 'bs'],
        ['Breton', 'br'],
        ['Bulgarian', 'bg'],
        ['Burmese', 'my'],
        ['Catalan', 'ca'],
        ['Chamorro', 'ch'],
        ['Chechen', 'ce'],
        ['Chichewa, Chewa, Nyanja', 'ny'],
        ['Chinese', 'zh'],
        ['Chinese (Simplified)', 'zh-Hans'],
        ['Chinese (Traditional)', 'zh-Hant'],
        ['Chuvash', 'cv'],
        ['Cornish', 'kw'],
        ['Corsican', 'co'],
        ['Cree', 'cr'],
        ['Croatian', 'hr'],
        ['Czech', 'cs'],
        ['Danish', 'da'],
        ['Divehi, Dhivehi, Maldivian', 'dv'],
        ['Dutch', 'nl'],
        ['Dzongkha', 'dz'],
        ['English', 'en'],
        ['Esperanto', 'eo'],
        ['Estonian', 'et'],
        ['Ewe', 'ee'],
        ['Faroese', 'fo'],
        ['Fijian', 'fj'],
        ['Finnish', 'fi'],
        ['French', 'fr'],
        ['Fula, Fulah, Pulaar, Pular', 'ff'],
        ['Galician', 'gl'],
        ['Gaelic (Scottish)', 'gd'],
        ['Gaelic (Manx)', 'gv'],
        ['Georgian', 'ka'],
        ['German', 'de'],
        ['Greek', 'el'],
        ['Greenlandic', 'kl'],
        ['Guarani', 'gn'],
        ['Gujarati', 'gu'],
        ['Haitian Creole', 'ht'],
        ['Hausa', 'ha'],
        ['Hebrew', 'he'],
        ['Herero', 'hz'],
        ['Hindi', 'hi'],
        ['Hiri Motu', 'ho'],
        ['Hungarian', 'hu'],
        ['Icelandic', 'is'],
        ['Ido', 'io'],
        ['Igbo', 'ig'],
        ['Indonesian', 'id, in'],
        ['Interlingua', 'ia'],
        ['Interlingue', 'ie'],
        ['Inuktitut', 'iu'],
        ['Inupiak', 'ik'],
        ['Irish', 'ga'],
        ['Italian', 'it'],
        ['Japanese', 'ja'],
        ['Javanese', 'jv'],
        ['Kalaallisut, Greenlandic', 'kl'],
        ['Kannada', 'kn'],
        ['Kanuri', 'kr'],
        ['Kashmiri', 'ks'],
        ['Kazakh', 'kk'],
        ['Khmer', 'km'],
        ['Kikuyu', 'ki'],
        ['Kinyarwanda (Rwanda)', 'rw'],
        ['Kirundi', 'rn'],
        ['Kyrgyz', 'ky'],
        ['Komi', 'kv'],
        ['Kongo', 'kg'],
        ['Korean', 'ko'],
        ['Kurdish', 'ku'],
        ['Kwanyama', 'kj'],
        ['Lao', 'lo'],
        ['Latin', 'la'],
        ['Latvian (Lettish)', 'lv'],
        ['Limburgish ( Limburger)', 'li'],
        ['Lingala', 'ln'],
        ['Lithuanian', 'lt'],
        ['Luga-Katanga', 'lu'],
        ['Luganda, Ganda', 'lg'],
        ['Luxembourgish', 'lb'],
        ['Manx', 'gv'],
        ['Macedonian', 'mk'],
        ['Malagasy', 'mg'],
        ['Malay', 'ms'],
        ['Malayalam', 'ml'],
        ['Maltese', 'mt'],
        ['Maori', 'mi'],
        ['Marathi', 'mr'],
        ['Marshallese', 'mh'],
        ['Moldavian', 'mo'],
        ['Mongolian', 'mn'],
        ['Nauru', 'na'],
        ['Navajo', 'nv'],
        ['Ndonga', 'ng'],
        ['Northern Ndebele', 'nd'],
        ['Nepali', 'ne'],
        ['Norwegian', 'no'],
        ['Norwegian bokmål', 'nb'],
        ['Norwegian nynorsk', 'nn'],
        ['Nuosu', 'ii'],
        ['Occitan', 'oc'],
        ['Ojibwe', 'oj'],
        ['Old Church Slavonic, Old Bulgarian', 'cu'],
        ['Oriya', 'or'],
        ['Oromo (Afaan Oromo)', 'om'],
        ['Ossetian', 'os'],
        ['Pāli', 'pi'],
        ['Pashto, Pushto', 'ps'],
        ['Persian (Farsi)', 'fa'],
        ['Polish', 'pl'],
        ['Portuguese', 'pt'],
        ['Punjabi (Eastern)', 'pa'],
        ['Quechua', 'qu'],
        ['Romansh', 'rm'],
        ['Romanian', 'ro'],
        ['Russian', 'ru'],
        ['Sami', 'se'],
        ['Samoan', 'sm'],
        ['Sango', 'sg'],
        ['Sanskrit', 'sa'],
        ['Serbian', 'sr'],
        ['Serbo-Croatian', 'sh'],
        ['Sesotho', 'st'],
        ['Setswana', 'tn'],
        ['Shona', 'sn'],
        ['Sichuan Yi', 'ii'],
        ['Sindhi', 'sd'],
        ['Sinhalese', 'si'],
        ['Siswati', 'ss'],
        ['Slovak', 'sk'],
        ['Slovenian', 'sl'],
        ['Somali', 'so'],
        ['Southern Ndebele', 'nr'],
        ['Spanish', 'es'],
        ['Sundanese', 'su'],
        ['Swahili (Kiswahili)', 'sw'],
        ['Swati', 'ss'],
        ['Swedish', 'sv'],
        ['Tagalog', 'tl'],
        ['Tahitian', 'ty'],
        ['Tajik', 'tg'],
        ['Tamil', 'ta'],
        ['Tatar', 'tt'],
        ['Telugu', 'te'],
        ['Thai', 'th'],
        ['Tibetan', 'bo'],
        ['Tigrinya', 'ti'],
        ['Tonga', 'to'],
        ['Tsonga', 'ts'],
        ['Turkish', 'tr'],
        ['Turkmen', 'tk'],
        ['Twi', 'tw'],
        ['Uyghur', 'ug'],
        ['Ukrainian', 'uk'],
        ['Urdu', 'ur'],
        ['Uzbek', 'uz'],
        ['Venda', 've'],
        ['Vietnamese', 'vi'],
        ['Volapük', 'vo'],
        ['Wallon', 'wa'],
        ['Welsh', 'cy'],
        ['Wolof', 'wo'],
        ['Western Frisian', 'fy'],
        ['Xhosa', 'xh'],
        ['Yiddish', 'yi', 'ji'],
        ['Yoruba', 'yo'],
        ['Zhuang, Chuang', 'za'],
        ['Zulu', 'zu'],
    ];

    return locales

}
export const getTimeZones = () => {
    let timeZones;
    if (Intl.supportedValuesOf('timeZone')) {
        timeZones = Intl.supportedValuesOf('timeZone');
    }else{
        timeZones = [
            'Europe/Andorra',
            'Asia/Dubai',
            'Asia/Kabul',
            'Europe/Tirane',
            'Asia/Yerevan',
            'Antarctica/Casey',
            'Antarctica/Davis',
            'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
            'Antarctica/Mawson',
            'Antarctica/Palmer',
            'Antarctica/Rothera',
            'Antarctica/Syowa',
            'Antarctica/Troll',
            'Antarctica/Vostok',
            'America/Argentina/Buenos_Aires',
            'America/Argentina/Cordoba',
            'America/Argentina/Salta',
            'America/Argentina/Jujuy',
            'America/Argentina/Tucuman',
            'America/Argentina/Catamarca',
            'America/Argentina/La_Rioja',
            'America/Argentina/San_Juan',
            'America/Argentina/Mendoza',
            'America/Argentina/San_Luis',
            'America/Argentina/Rio_Gallegos',
            'America/Argentina/Ushuaia',
            'Pacific/Pago_Pago',
            'Europe/Vienna',
            'Australia/Lord_Howe',
            'Antarctica/Macquarie',
            'Australia/Hobart',
            'Australia/Currie',
            'Australia/Melbourne',
            'Australia/Sydney',
            'Australia/Broken_Hill',
            'Australia/Brisbane',
            'Australia/Lindeman',
            'Australia/Adelaide',
            'Australia/Darwin',
            'Australia/Perth',
            'Australia/Eucla',
            'Asia/Baku',
            'America/Barbados',
            'Asia/Dhaka',
            'Europe/Brussels',
            'Europe/Sofia',
            'Atlantic/Bermuda',
            'Asia/Brunei',
            'America/La_Paz',
            'America/Noronha',
            'America/Belem',
            'America/Fortaleza',
            'America/Recife',
            'America/Araguaina',
            'America/Maceio',
            'America/Bahia',
            'America/Sao_Paulo',
            'America/Campo_Grande',
            'America/Cuiaba',
            'America/Santarem',
            'America/Porto_Velho',
            'America/Boa_Vista',
            'America/Manaus',
            'America/Eirunepe',
            'America/Rio_Branco',
            'America/Nassau',
            'Asia/Thimphu',
            'Europe/Minsk',
            'America/Belize',
            'America/St_Johns',
            'America/Halifax',
            'America/Glace_Bay',
            'America/Moncton',
            'America/Goose_Bay',
            'America/Blanc-Sablon',
            'America/Toronto',
            'America/Nipigon',
            'America/Thunder_Bay',
            'America/Iqaluit',
            'America/Pangnirtung',
            'America/Atikokan',
            'America/Winnipeg',
            'America/Rainy_River',
            'America/Resolute',
            'America/Rankin_Inlet',
            'America/Regina',
            'America/Swift_Current',
            'America/Edmonton',
            'America/Cambridge_Bay',
            'America/Yellowknife',
            'America/Inuvik',
            'America/Creston',
            'America/Dawson_Creek',
            'America/Fort_Nelson',
            'America/Vancouver',
            'America/Whitehorse',
            'America/Dawson',
            'Indian/Cocos',
            'Europe/Zurich',
            'Africa/Abidjan',
            'Pacific/Rarotonga',
            'America/Santiago',
            'America/Punta_Arenas',
            'Pacific/Easter',
            'Asia/Shanghai',
            'Asia/Urumqi',
            'America/Bogota',
            'America/Costa_Rica',
            'America/Havana',
            'Atlantic/Cape_Verde',
            'America/Curacao',
            'Indian/Christmas',
            'Asia/Nicosia',
            'Asia/Famagusta',
            'Europe/Prague',
            'Europe/Berlin',
            'Europe/Copenhagen',
            'America/Santo_Domingo',
            'Africa/Algiers',
            'America/Guayaquil',
            'Pacific/Galapagos',
            'Europe/Tallinn',
            'Africa/Cairo',
            'Africa/El_Aaiun',
            'Europe/Madrid',
            'Africa/Ceuta',
            'Atlantic/Canary',
            'Europe/Helsinki',
            'Pacific/Fiji',
            'Atlantic/Stanley',
            'Pacific/Chuuk',
            'Pacific/Pohnpei',
            'Pacific/Kosrae',
            'Atlantic/Faroe',
            'Europe/Paris',
            'Europe/London',
            'Asia/Tbilisi',
            'America/Cayenne',
            'Africa/Accra',
            'Europe/Gibraltar',
            'America/Godthab',
            'America/Danmarkshavn',
            'America/Scoresbysund',
            'America/Thule',
            'Europe/Athens',
            'Atlantic/South_Georgia',
            'America/Guatemala',
            'Pacific/Guam',
            'Africa/Bissau',
            'America/Guyana',
            'Asia/Hong_Kong',
            'America/Tegucigalpa',
            'America/Port-au-Prince',
            'Europe/Budapest',
            'Asia/Jakarta',
            'Asia/Pontianak',
            'Asia/Makassar',
            'Asia/Jayapura',
            'Europe/Dublin',
            'Asia/Jerusalem',
            'Asia/Kolkata',
            'Indian/Chagos',
            'Asia/Baghdad',
            'Asia/Tehran',
            'Atlantic/Reykjavik',
            'Europe/Rome',
            'America/Jamaica',
            'Asia/Amman',
            'Asia/Tokyo',
            'Africa/Nairobi',
            'Asia/Bishkek',
            'Pacific/Tarawa',
            'Pacific/Enderbury',
            'Pacific/Kiritimati',
            'Asia/Pyongyang',
            'Asia/Seoul',
            'Asia/Almaty',
            'Asia/Qyzylorda',
            'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
            'Asia/Aqtobe',
            'Asia/Aqtau',
            'Asia/Atyrau',
            'Asia/Oral',
            'Asia/Beirut',
            'Asia/Colombo',
            'Africa/Monrovia',
            'Europe/Vilnius',
            'Europe/Luxembourg',
            'Europe/Riga',
            'Africa/Tripoli',
            'Africa/Casablanca',
            'Europe/Monaco',
            'Europe/Chisinau',
            'Pacific/Majuro',
            'Pacific/Kwajalein',
            'Asia/Yangon',
            'Asia/Ulaanbaatar',
            'Asia/Hovd',
            'Asia/Choibalsan',
            'Asia/Macau',
            'America/Martinique',
            'Europe/Malta',
            'Indian/Mauritius',
            'Indian/Maldives',
            'America/Mexico_City',
            'America/Cancun',
            'America/Merida',
            'America/Monterrey',
            'America/Matamoros',
            'America/Mazatlan',
            'America/Chihuahua',
            'America/Ojinaga',
            'America/Hermosillo',
            'America/Tijuana',
            'America/Bahia_Banderas',
            'Asia/Kuala_Lumpur',
            'Asia/Kuching',
            'Africa/Maputo',
            'Africa/Windhoek',
            'Pacific/Noumea',
            'Pacific/Norfolk',
            'Africa/Lagos',
            'America/Managua',
            'Europe/Amsterdam',
            'Europe/Oslo',
            'Asia/Kathmandu',
            'Pacific/Nauru',
            'Pacific/Niue',
            'Pacific/Auckland',
            'Pacific/Chatham',
            'America/Panama',
            'America/Lima',
            'Pacific/Tahiti',
            'Pacific/Marquesas',
            'Pacific/Gambier',
            'Pacific/Port_Moresby',
            'Pacific/Bougainville',
            'Asia/Manila',
            'Asia/Karachi',
            'Europe/Warsaw',
            'America/Miquelon',
            'Pacific/Pitcairn',
            'America/Puerto_Rico',
            'Asia/Gaza',
            'Asia/Hebron',
            'Europe/Lisbon',
            'Atlantic/Madeira',
            'Atlantic/Azores',
            'Pacific/Palau',
            'America/Asuncion',
            'Asia/Qatar',
            'Indian/Reunion',
            'Europe/Bucharest',
            'Europe/Belgrade',
            'Europe/Kaliningrad',
            'Europe/Moscow',
            'Europe/Simferopol',
            'Europe/Kirov',
            'Europe/Astrakhan',
            'Europe/Volgograd',
            'Europe/Saratov',
            'Europe/Ulyanovsk',
            'Europe/Samara',
            'Asia/Yekaterinburg',
            'Asia/Omsk',
            'Asia/Novosibirsk',
            'Asia/Barnaul',
            'Asia/Tomsk',
            'Asia/Novokuznetsk',
            'Asia/Krasnoyarsk',
            'Asia/Irkutsk',
            'Asia/Chita',
            'Asia/Yakutsk',
            'Asia/Khandyga',
            'Asia/Vladivostok',
            'Asia/Ust-Nera',
            'Asia/Magadan',
            'Asia/Sakhalin',
            'Asia/Srednekolymsk',
            'Asia/Kamchatka',
            'Asia/Anadyr',
            'Asia/Riyadh',
            'Pacific/Guadalcanal',
            'Indian/Mahe',
            'Africa/Khartoum',
            'Europe/Stockholm',
            'Asia/Singapore',
            'America/Paramaribo',
            'Africa/Juba',
            'Africa/Sao_Tome',
            'America/El_Salvador',
            'Asia/Damascus',
            'America/Grand_Turk',
            'Africa/Ndjamena',
            'Indian/Kerguelen',
            'Asia/Bangkok',
            'Asia/Dushanbe',
            'Pacific/Fakaofo',
            'Asia/Dili',
            'Asia/Ashgabat',
            'Africa/Tunis',
            'Pacific/Tongatapu',
            'Europe/Istanbul',
            'America/Port_of_Spain',
            'Pacific/Funafuti',
            'Asia/Taipei',
            'Europe/Kiev',
            'Europe/Uzhgorod',
            'Europe/Zaporozhye',
            'Pacific/Wake',
            'America/New_York',
            'America/Detroit',
            'America/Kentucky/Louisville',
            'America/Kentucky/Monticello',
            'America/Indiana/Indianapolis',
            'America/Indiana/Vincennes',
            'America/Indiana/Winamac',
            'America/Indiana/Marengo',
            'America/Indiana/Petersburg',
            'America/Indiana/Vevay',
            'America/Chicago',
            'America/Indiana/Tell_City',
            'America/Indiana/Knox',
            'America/Menominee',
            'America/North_Dakota/Center',
            'America/North_Dakota/New_Salem',
            'America/North_Dakota/Beulah',
            'America/Denver',
            'America/Boise',
            'America/Phoenix',
            'America/Los_Angeles',
            'America/Anchorage',
            'America/Juneau',
            'America/Sitka',
            'America/Metlakatla',
            'America/Yakutat',
            'America/Nome',
            'America/Adak',
            'Pacific/Honolulu',
            'America/Montevideo',
            'Asia/Samarkand',
            'Asia/Tashkent',
            'America/Caracas',
            'Asia/Ho_Chi_Minh',
            'Pacific/Efate',
            'Pacific/Wallis',
            'Pacific/Apia',
            'Africa/Johannesburg'
        ]
    }

    return timeZones;
}
